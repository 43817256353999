<template>
    <div v-if="isList" class="content-wrapper">
        <bo-page-title></bo-page-title>
        <div class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row">
                                <div class="col-md-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-row">
                                <div class="col-md-4" v-if="isshowTable('mpo_name')">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0 d-flex">
                                        <date-range-picker
                                            ref="picker"
                                            :locale-data="datePickerConfig.locale"
                                            :autoApply="datePickerConfig.autoApply"
                                            v-model="dateRange"
                                            :opens="'right'"
                                            :ranges="datePickerConfig.ranges"
                                            @update="updateValues"
                                        >
                                            <template v-slot:input="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                        </date-range-picker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="table-responsive sticky-table">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th v-if="isshowTable('aur_reg_code')">No. Kedatangan</th>
                                <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                <th v-if="isshowTable('aur_reg_date')">Tanggal Daftar</th>
                                <th v-if="isshowTable('ap_code')">No. RM</th>
                                <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                <th v-if="isshowTable('ap_usia')">Usia</th>
                                <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                                <th>Status</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody v-if="!loadingTable" >
                            <template v-for="(v,k) in dataReg" >
                                <tr :key="k">
                                    <td>{{k+1}}</td>
                                    <td v-if="isshowTable('aur_reg_code')">
                                    {{v.aur_reg_code||"-"}}
                                    <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                    <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                    <td v-if="isshowTable('ap_fullname')">
                                    <router-link v-if="moduleRole('view') && v.aur_is_have_hais == 'Y'"
                                        :to="{name: $route.name, params: {pageSlug: v.aur_id}, query: {detail: true}}"
                                        v-b-tooltip.hovv-ber title="Lihat Detail Hais" class="font-weight-semibold">
                                        {{uppercaseWord(v.ap_fullname)||'-'}}
                                    </router-link>
                                    <span v-else>{{uppercaseWord(v.ap_fullname)||'-'}}</span>
                                    <p>{{v.ap_nik||"-"}}</p>

                                    <b-badge v-if="(v.aur_tindak_lanjut||[]).indexOf('RI') != -1" variant="primary"
                                        >Pasien Rawat Inap</b-badge>
                                    </td>
                                    
                                    <td v-if="isshowTable('aur_reg_date')">
                                    {{v.aur_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                    <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                    <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                    <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                    <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                    <td v-if="isshowTable('ap_usia')" v-b-tooltip.hover>
                                    {{v.ap_usia_with_ket||"-"}}
                                    <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                    </td>
                                    <td v-if="isshowTable('cg_label')">{{v.cg_label}}</td>
                                    <td>
                                        <b-badge
                                            v-if="v.aur_is_have_hais=='Y'"
                                            variant="success"
                                        >Selesai</b-badge>
                                        <b-badge
                                            v-else
                                            variant="warning"
                                        >Pending</b-badge>
                                    </td>
                                    <td>
                                        <router-link class="btn alpha-danger border-danger text-danger-800 btn-icon rounded-round"
                                            v-if="moduleRole('add_hais')"
                                            :to="{name: $route.name, params: {pageSlug: v.aur_id}}"
                                            data-toggle="tooltip" data-placement="top" v-b-tooltip.hover :title="'Tambah HAIS'"><i
                                        class="icon-plus-circle2"></i></router-link>
                                        <span v-else> - </span>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="loadingTable"> 
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                        </tbody>
                        <tbody v-if="loadingInvinite">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                        </tbody>
                        <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                            <tr>
                                <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                    Data Tidak Ditemukan
                                </th>
                            </tr>                
                        </tbody>                
                    </table>
                    <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100" v-else>
        <Form v-if="!isDetail" :row.sync="row" v-bind="passToSub" />
        <FormDetail v-else :row.sync="row" v-bind="passToSub"/>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import Form from './Form.vue'
import FormDetail from './FormDetail.vue'

export default {
    extends: GlobalVue,
    data() {
        return {
            // LIST COMP ==============
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            rowReg: {},
            loadingInvinite: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            selectedFilter: [
                'aur_reg_code','ap_fullname','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_penunjang','ap_code'
            ],
            acceptedFilter: [
                'aur_reg_code','ap_fullname','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            // LIST COMP ================
            dataHais: [],
            mHais: [],
            riwayatHais: []
        }
    },
    computed:{
        passToSub(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataHais: this.dataHais,
                mHais: this.mHais,
                riwayatHais: this.riwayatHais
            })
        },
        isDetail(){
            return this.$route.query.detail
        }
    },
  	components:{DateRangePicker,Form,FormDetail},
    methods: {
        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query

            let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")

            if(this.isList){
                this.doConvertDate()
                if((+this.$route.query.page||1) == 1){
                    this.loadingTable = true
                }
            }else{
                this.loadingOverlay = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage+slugs, 
                {
                    params: Object.assign({
                        selectedFilter: this.isList ? this.selectedFilter.join(",") : '',
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data    
                if(this.isList){
                    this.loadingTable = false
                    this.loadingInvinite = false
                    this.totalData = resp.dataReg.total

                    if((+this.$route.query.page||1) !== 1){
                        let data = resp.dataReg.data
                        for(let i = 0; i < (data||[]).length; i++){
                            this.dataReg.push(data[i])
                        }
                    }else{
                        this.dataReg = resp.dataReg.data
                    }   
                }else{
                    this.loadingOverlay = false
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
        
                    if(!this.isFound){
                        this.$router.push({name : this.$route.name}).catch(()=>{})
                    }
                }
            })
            
            Gen.apiRest(
                "/get/"+'Master'+
                '/poli'
            ).then(res=>{
                let resp = res.data
                this.mPoli = resp.mPoli
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Hais' && this.isList){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
        },

        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },

        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                const responsiveTableHead = responsiveTable.querySelector('thead')
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                const tableOffsetTop = responsiveTable.offsetTop + responsiveTable.offsetParent.offsetTop
                responsiveTableHead.style.position = 'relative'
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                window.addEventListener('scroll', e => {
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    if (document.documentElement.scrollTop > tableOffsetTop) {
                        responsiveTableHead.style.top = (document.documentElement.scrollTop - tableOffsetTop)+64+'px'
                    }else{
                        responsiveTableHead.style.top = '0px'
                    }
                })
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                         
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
    },
    mounted() {
        if(this.isList){
            this.filter.page = 1
            if(this.$route.query.page != 1){
                let url = {
                    name:this.$route.name,
                    params: (this.$route.params||{}),
                    query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
                }
                this.$router.push(url).catch(()=>{})
            }else{
                this.apiGet()
            }
        }else{
            this.apiGet()
        }
        
        setTimeout(()=>{
            if(this.isList){
                this.setSlide()
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            }
        },1000)
        const stickyTable = document.querySelector('.sticky-table')
        if(stickyTable){
            stickyTable.addEventListener('scroll', this.handleScroll)
        }
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
    },
    watch:{
        $route(){
            this.apiGet()
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>